import React from 'react'
import { Layout } from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="py-64 text-center text-5xl font-bold">404 - Seite nicht gefunden</div>
  </Layout>
)

export default NotFoundPage
